<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="formValidation">
    <b-form @submit.prevent="handleSubmit(onSubmit)">
      <b-form-group
        label="Бенефіціар"
        label-size="lg"
        label-class="font-weight-bold"
      >
        <ValidationProvider
          name="Прізвище"
          :rules="{
            regex: $stringConstants('REGEX_TEXT_FIO'),
            max: 255,
            required: true,
          }"
          v-slot="{ errors, dirty }"
          vid="last_name"
        >
          <b-form-group label="Прізвище">
            <b-form-input
              type="text"
              v-model="form.last_name"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="ім'я"
          :rules="{
            regex: $stringConstants('REGEX_TEXT_FIO'),
            max: 255,
            required: true,
          }"
          v-slot="{ errors, dirty }"
          vid="first_name"
        >
          <b-form-group label="Ім'я">
            <b-form-input
              type="text"
              v-model="form.first_name"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="по батькові"
          :rules="{
            regex: $stringConstants('REGEX_TEXT_FIO'),
            max: 255,
          }"
          v-slot="{ errors, dirty }"
          vid="middle_name"
        >
          <b-form-group label="По батькові">
            <b-form-input
              type="text"
              v-model="form.middle_name"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="дата народження"
          rules="required"
          v-slot="{ errors }"
          vid="birthday"
        >
          <b-form-group label="Дата народження">
            <b-form-input
              type="date"
              v-model="form.birthday"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="РНОКПП"
          :rules="{ required: true, regex: /^[\d]{10}$/ }"
          v-slot="{ errors }"
          vid="itn_code"
        >
          <b-form-group label="РНОКПП" label-for="rnokpp">
            <b-form-input
              type="text"
              v-model="form.itn_code"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="Країна громадянства"
          :rules="{ max: 255 }"
          v-slot="{ errors }"
          vid="citizenship"
        >
          <b-form-group label="Країна громадянства">
            <b-form-input
              type="text"
              v-model="form.citizenship"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="Країна проживання"
          :rules="{ max: 255 }"
          v-slot="{ errors }"
          vid="country"
        >
          <b-form-group label="Країна проживання">
            <b-form-input
              type="text"
              v-model="form.country"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="Країна громадянства"
          :rules="{ max: 255 }"
          v-slot="{ errors }"
          vid="influence_basis"
        >
          <b-form-group label="Підстава впливу">
            <b-form-input
              type="text"
              v-model="form.influence_basis"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          name="Дата початку"
          v-slot="{ errors, dirty }"
          vid="started_at"
        >
          <b-form-group label="Дата початку">
            <b-form-input
              type="date"
              v-model="form.started_at"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          name="Дата закінчення"
          v-slot="{ errors, dirty }"
          vid="ended_at"
        >
          <b-form-group label="Дата закінчення">
            <b-form-input
              type="date"
              v-model="form.ended_at"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          name="Належність до публічних осіб"
          v-slot="{ errors, dirty }"
          vid="is_public"
        >
          <b-form-group label="Належність до публічних осіб">
            <b-form-radio-group
              stacked
              v-model="form.is_public"
              :options="[
                { text: 'Так', value: true },
                { text: 'Нi', value: false },
              ]"
              :plain="true"
            >
            </b-form-radio-group>
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="Належність до терористів"
          v-slot="{ errors, dirty }"
          vid="is_terrorist"
        >
          <b-form-group label="Належність до терористів">
            <b-form-radio-group
              stacked
              v-model="form.is_terrorist"
              :options="[
                { text: 'Так', value: true },
                { text: 'Нi', value: false },
              ]"
              :plain="true"
            >
            </b-form-radio-group>
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="Належність до санкцій"
          v-slot="{ errors, dirty }"
          vid="has_sanctions"
        >
          <b-form-group label="Належність до санкцій">
            <b-form-radio-group
              stacked
              v-model="form.has_sanctions"
              :options="[
                { text: 'Так', value: true },
                { text: 'Нi', value: false },
              ]"
              :plain="true"
            >
            </b-form-radio-group>
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-form-group>
      <div slot="footer">
        <b-button
          v-if="$auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))"
          type="submit"
          size="sm"
          :variant="propBeneficialOwner ? 'primary' : 'success'"
        >
          <i class="fa fa-dot-circle-o"></i>
          {{ propBeneficialOwner ? "Зберегти" : "Додати" }}
        </b-button>
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
export default {
  name: "BeneficialOwnerForm",
  props: {
    propBeneficialOwner: {
      type: Object | null,
      required: true,
    },
  },
  data() {
    return {
      form: {
        id: null,
        last_name: null,
        first_name: null,
        middle_name: null,
        birthday: null,
        itn_code: null,
        country: null,
        citizenship: null,
        influence_basis: null,
        is_public: false,
        is_terrorist: false,
        has_sanctions: false,
        started_at: null,
        ended_at: null,
      },
    };
  },
  created() {
    for (const [key] of Object.entries(this.form)) {
      if (this.propBeneficialOwner) {
        this.form[key] = this.propBeneficialOwner[key];
      } else {
        this.form[key] =
          key === "is_public" ||
          key === "is_terrorist" ||
          key === "has_sanctions"
            ? false
            : null;
      }
    }
  },
  methods: {
    getChangedBeneficialOwnerFields() {
      return Object.keys(this.$refs.formValidation.fields).reduce(
        (accumulator, key) => {
          if (
            this.$refs.formValidation.fields[key].changed === true &&
            key in this.form
          ) {
            accumulator.push(key);
          }

          return accumulator;
        },
        []
      );
    },
    onSubmit() {
      let changedBeneficialOwnerFields = this.getChangedBeneficialOwnerFields();

      let fields = Object.keys(this.form).reduce((accumulator, key) => {
        if (changedBeneficialOwnerFields.includes(key) || key === "id") {
          accumulator[key] = this.form[key];
        }

        return accumulator;
      }, {});

      fields.counterpart_id = this.$route.params.id;
      this.$store
        .dispatch(
          this.propBeneficialOwner
            ? "counterpart/updateBeneficialOwner"
            : "counterpart/createBeneficialOwner",
          fields
        )
        .then(() => {
          this.$root.$emit("bv::hide::modal", "modalBeneficialOwner");
        });
    },
  },
};
</script>

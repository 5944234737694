<template>
  <ValidationObserver
    ref="formValidation"
    v-slot="{ handleSubmit, invalid, dirty }"
  >
    <b-alert :show="Object.keys(errors).length > 0" variant="danger">
      <h4 class="alert-heading">
        Виникла помилка при додаванні оцінки фінансвого стану!
      </h4>
      <li v-for="(error, index) in errors" :key="index">
        <span v-for="(e, i) in error" :key="i">
          {{ e }}
        </span>
      </li>
    </b-alert>

    <b-card bg-variant="light">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-form-group
          v-for="(item, index) in list"
          v-bind:key="index"
          label-size="lg"
          label-class="font-weight-bold"
          :label="item.title"
        >
          <span v-for="(field, idx) in item.fields" v-bind:key="idx">
            <ValidationProvider
              v-if="field.type === 'boolean'"
              :name="field.label"
              :vid="field.name"
              v-slot="{ errors, dirty, validated, valid }"
            >
              <b-form-checkbox
                v-bind:key="idx"
                v-model="form[field.name]"
                :disabled="isDisabled(field.disabled_if_true)"
              >
                {{ field.label }}
              </b-form-checkbox>
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </ValidationProvider>

            <ValidationProvider
              v-if="field.type === 'float'"
              :name="field.label"
              :rules="{ regex: /^[\d*(?:\.\d+)?]/ }"
              v-slot="{ errors, dirty, validated, valid }"
              :vid="field.name"
            >
              <label for="input-none">{{ field.label }}</label>
              <b-form-input
                type="number"
                v-model.number="form[field.name]"
                :disabled="isDisabled(field.disabled_if_true)"
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </ValidationProvider>

            <ValidationProvider
              v-if="field.type === 'number'"
              :name="field.label"
              rules="max_value:32767"
              v-slot="{ errors, dirty, validated, valid }"
              :vid="field.name"
            >
              <label for="input-none">{{ field.label }}</label>
              <b-form-input
                type="number"
                :formatter="formatter"
                v-model.number="form[field.name]"
                :disabled="isDisabled(field.disabled_if_true)"
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </span>
        </b-form-group>

        <b-button
          v-if="$auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))"
          variant="success"
          size="sm"
          @click="onSubmit"
          :disabled="invalid || !dirty"
        >
          <i class="fa fa-dot-circle-o"></i> Додати оцінку фінансового стану
        </b-button>
      </b-form>
    </b-card>
  </ValidationObserver>
</template>

<script>
import storage from "../../../../api/api";

export default {
  name: "FinancialAssessmentForm",
  created() {
    storage
      .getFinancialAssessmentByCounterpart(this.$route.params.id)
      .then(({ data }) => {
        this.$set(this, "list", data);
      })
      .catch(() => {
        this.$snotify.error(
          "Оцінка фінансового стану для цього типу контрагента не підтримується"
        );
        this.$root.$emit("bv::hide::modal", "modalFinancialAssessment");
      });
  },
  data() {
    return {
      errors: {},
      form: {
        counterpart_id: this.$route.params.id
      },
      list: []
    };
  },
  methods: {
    onSubmit() {
      this.$store
        .dispatch(
          "counterpart/createFinancialAssessment",
          Object.keys(this.form).reduce((accumulator, key) => {
            if (this.form[key] !== null && this.form[key] !== "") {
              accumulator[key] = this.form[key];
            }

            return accumulator;
          }, {})
        )
        .then(() => {
          this.$root.$emit("bv::hide::modal", "modalFinancialAssessment");
        })
        .catch(({ response }) => {
          this.errors = {};

          for (let variable in response.data.description) {
            this.errors[variable] = response.data.description[variable];
          }

          this.$refs.formValidation.setErrors(this.errors);
          this.$snotify.error("Не збережено");
        });
    },
    formatter(value) {
      return String(value)
        .replace(/\.|,/gi, "")
        .substring(0, 15);
    },
    isDisabled(arr) {
      return arr.some(elem => {
        if (!this.form[elem]) return false;
        return this.form[elem] === true || this.form[elem];
      });
    }
  }
};
</script>

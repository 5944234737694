<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(onSubmit)">
      <b-form-group
        label="Підрозділ"
        label-size="lg"
        label-class="font-weight-bold"
      >
        <ValidationProvider
          name="назва підрозділу"
          :rules="{
            required: true,
            regex: $stringConstants('REGEX_TEXT_NAME')
          }"
          v-slot="{ errors }"
        >
          <b-form-group label="Назва підрозділу">
            <b-form-input
              type="text"
              v-model="form.name"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          name="ЄДРПОУ"
          :rules="{ required: true, regex: /^[\d]{8}$/ }"
          v-slot="{ errors }"
        >
          <b-form-group label="ЄДРПОУ">
            <b-form-input
              type="text"
              v-model="form.usreou_code"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <b-form-group label="Належність до терористів">
          <b-form-radio-group
            stacked
            v-model="form.is_terrorist"
            :options="[
              { text: 'Так', value: true },
              { text: 'Нi', value: false }
            ]"
            :plain="true"
          />
        </b-form-group>

        <b-form-group label="Належність до санкцій">
          <b-form-radio-group
            stacked
            v-model="form.has_sanctions"
            :options="[
              { text: 'Так', value: true },
              { text: 'Нi', value: false }
            ]"
            :plain="true"
          />
        </b-form-group>
      </b-form-group>

      <div slot="footer">
        <b-button
          v-if="$auth.can($stringConstants('PERMISSION_CREATE_COUNTERPART'))"
          type="submit"
          size="sm"
          :variant="index ? 'primary' : 'success'"
        >
          <i class="fa fa-dot-circle-o"></i> {{ index ? "Зберегти" : "Додати" }}
        </b-button>
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
export default {
  name: "SeparateUnitForm",
  props: {
    index: null
  },
  computed: {
    form: function() {
      if (this.index === null)
        return {
          counterpart_id: this.$route.params.id,
          name: "",
          usreou_code: "",
          is_terrorist: false,
          has_sanctions: false
        };
      let form = this.$store.getters["counterpart/getSeparateUnits"][
        this.index
      ];
      return {
        id: form.id,
        name: form.name,
        usreou_code: form.usreou_code,
        is_terrorist: form.is_terrorist,
        has_sanctions: form.has_sanctions
      };
    }
  },
  methods: {
    onSubmit() {
      if (this.index === null)
        this.$store.dispatch("counterpart/createSeparateUnit", this.form);
      else this.$store.dispatch("counterpart/updateSeparateUnit", this.form);
      this.$root.$emit("bv::hide::modal", "modalSeparateUnit");
    }
  }
};
</script>
